'use client'

import styles from './RouteStyleProvider.module.css'
import classNamesBind from 'classnames/bind'
import { usePathname } from 'next/navigation'
const cn = classNamesBind.bind(styles)

interface OwnProps {
  children: React.ReactNode
}

export default function RouteStyleProvider({ children }: OwnProps) {
  const pathName = usePathname()
  return (
    <body
      className={cn('body', {
        enterprise: ['/people'].includes(pathName || ''),
      })}
    >
      {children}
    </body>
  )
}
